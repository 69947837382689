import React from 'react'

export const Introduction = () => {
  return (
    <div className="text-center max-w-lg mx-auto">
      <h2 className="text-4xl lg:text-5xl text-blue-400 font-bold">
        Meet the Team
      </h2>
      <p className="mt-4 text-sm  lg:text-lg text-gray-400">
        Time and time again, our team has been praised for their
        professionalism, dedication to their work, and pride in the production
        of stunning bathrooms.
      </p>
    </div>
  )
}
