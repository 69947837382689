import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, SEO } from '@global'
import {
  Hero,
  FiftyFifty,
  Blockquote,
  Testimonials,
  SocialProof,
} from '@layouts'
import { TeamMembers } from '@layouts/Custom/TeamMembers/TeamMembers'
import { testimonials as testimonialsData } from '../../data/testimonials'

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "about/Hero_About.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dan: file(relativePath: { eq: "about/Dad.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quote: file(relativePath: { eq: "about/Quote_About.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const testimonials = testimonialsData.slice(4, 7)

  return (
    <div className="relative">
      <SEO title="About" />
      <Layout>
        <Hero
          fluid={data.hero.childImageSharp.fluid}
          eyebrow="serving you since 1959"
          title="A family business that puts people first"
        />
        <FiftyFifty
          fluid={data.dan.childImageSharp.fluid}
          eyebrow="meet the owner"
          heading="dan updike"
          className="py-8 sm:pt-12 lg:py-24"
        >
          <p>
            Dan started working in the family business when he was 18 years old
            as an installation helper. A few years in, he decided that he wanted
            to stay in the business permanently, because of his enjoyment and
            love for his work. You can find him spending time with family, snow
            skiing, and hiking when he’s out of the office.
          </p>
          <p className="mt-8">
            Dan takes on a personal role with every customer to truly understand
            their needs and make sure that everyone is on the same page from the
            beginning.
          </p>
        </FiftyFifty>
        <Blockquote fluid={data.quote.childImageSharp.fluid}>
          The installer’s{' '}
          <span className="text-blue-200">
            skills, professionalism, courtesy,
          </span>{' '}
          and <span className="text-blue-200">respect of our home</span> should
          be the role model for anyone learning this trade. This is the{' '}
          <span className="text-blue-200">best experience</span> we have ever
          had with someone working in our home.
        </Blockquote>
        <TeamMembers className="py-10 lg:mt-8 xl:mt-12" />
        <Testimonials data={testimonials} />
        <SocialProof />
      </Layout>
    </div>
  )
}
export default AboutPage
