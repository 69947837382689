import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { TeamMember } from './TeamMember'
import { Introduction } from './Introduction'
import { CTA } from './CTA'
import { teamMembersData } from './data/team'

export const TeamMembers = ({ className = 'py-10' }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { order: ASC, fields: name }
        filter: { relativeDirectory: { eq: "about/team" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  return (
    <section className={className}>
      <div className="container">
        <Introduction />
        <div className="mt-8 md:mt-10 lg:mt-16 grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10 lg:gap-16 xl:gap-32 max-w-lg md:max-w-none mx-auto">
          {teamMembersData.map((teamMember, idx) => (
            <TeamMember
              key={teamMember.name}
              fluid={images[idx].node.childImageSharp.fluid}
              name={teamMember.name}
              title={teamMember.title}
              bio={teamMember.bio}
            />
          ))}
        </div>
        <CTA />
      </div>
    </section>
  )
}
