export const testimonials = [
  {
    content: `It is obvious that Alex is very proud of his work. He always asked for input when installing. Always let us know when he would arrive, which was appreciated. We are very pleased.`,
    name: `Judy`,
    location: `GREENWOOD`,
  },
  {
    content: `The crew was so awesome. They were skillful, cleaned up, careful in my house, prompt and the job is outstanding! I would highly recommend Updike and Dean! `,
    name: `Kathy`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `The installers were always willing to answer my questions and discuss my aesthetic concepts. I appreciate the attention to detail. I am completely thrilled! You should see the room with the morning sunlight! Better than I had dreamed! `,
    name: `Margaret`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `I love how it looks! Ron was helpful in choosing the right color to complement the tile and included me on the decisions. Thanks for a lovely update to my bathroom! `,
    name: `Jen`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `I was so impressed with the way the guys kept me informed day to day of their progress. Our master bath looks like a showroom. I am completely satisfied and give the installers a 10! `,
    name: `Sheila`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `We are more than satisfied with the job. It was quick, efficient, and painless. We are ready to schedule a remodel date for our second bathroom.`,
    name: `Richard`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `I am very impressed with the clean up. Jeff was very careful to cover every inch of our carpet and the bathroom was completely cleaned at the end of each day. `,
    name: `Christinia`,
    location: `LIZTON`,
  },
  {
    content: `Very impressed with the work and the way the bathroom turned out. They work very meticulously at every step and insured the install was of the highest quality.`,
    name: `Gary`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `The installer’s skills, professionalism, courtesy, and respect of our home should be the role model for anyone learning this trade. This is the best experience we have ever had with someone working in our home. `,
    name: `Irene`,
    location: `GREENWOOD`,
  },
  {
    content: `Dean and his crew were always professional - a “no nonsense” group of guys that always put in a full workday...VERY HARD WORKERS. I’m so satisfied - so happy with the results. The bathroom is beautiful! `,
    name: `Angela`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `I am very satisfied with the products I chose for the remodel, and with the professional installation. I can and will recommend your company to friends and family.`,
    name: `Sandra`,
    location: `GREENWOOD`,
  },
  {
    content: `In today’s consumer experience we don’t always get courteous and polite from workers coming into our homes. Your installer was very courteous and polite—as well as professional. `,
    name: `Lois`,
    location: `INDIANAPOLIS`,
  },
  {
    content: `Jeff always took the time to answer our questions. Would show me what they meant, even going out to the garage to get the item in question. I love the look of the bathroom. Highly recommend your company. `,
    name: `Pat`,
    location: `GREENWOOD`,
  },
  {
    content: `Looks beautiful! The installer certainly knows what he’s doing. Thanks to Sherri as well since she helped me pick out the colors (my wife loves them). Thanks! `,
    name: `James`,
    location: `CARMEL`,
  },
  {
    content: `Your company has always done an excellent job. I will continue to refer you to friends who need work done. Thank you! `,
    name: `Cheryl`,
    location: `INDIANAPOLIS`,
  },
]
