export const teamMembersData = [
  {
    name: 'Sheri',
    title: 'office manager',
    bio:
      'Sheri has been with Updike for almost 20 years. She especially loves helping customers select colors and materials. When she’s not in the office, she likes to camp with her family, walk her dog and exercise.',
  },
  {
    name: 'Jeff',
    title: 'crew leader',
    bio:
      'Jeff has 15 years with Updike under his belt, with demolition being his favorite part of the remodel. For fun, he enjoys going to races and fishing.',
  },
  {
    name: 'Ron',
    title: 'crew leader',
    bio:
      'Ron has been with Updike for 2 years and loves seeing the end result of remodeled bathrooms. Fishing, travel, and farm work are among the things he likes to do in his spare time.',
  },
  {
    name: 'Jeremy',
    title: 'crew leader',
    bio:
      'After 8 years with the company, Jeremy’s favorite part of the remodel is finishing and seeing the final product. His favorite pasttime is spending time and playing with his kids.',
  },
  {
    name: 'Dean',
    title: 'crew leader',
    bio:
      'Dean has been with Updike for a little over 2 years with plumbing and electrical being his favorite part of the remodel. When he’s not at work, his favorite thing to do is play Mario Kart with his daughter.',
  },
  {
    name: 'Alex',
    title: 'crew leader',
    bio:
      'Soon to be 2 years with the company, Alex is very passionate about what he does and loves every part of the remodel. In his free time, he likes fishing, hunting, dancing, and playing with his kids.',
  },
]
