import React from 'react'
import Img from 'gatsby-image'

export const TeamMember = ({ bio, fluid, name, title }) => {
  return (
    <figure className="">
      <div className="rounded overflow-hidden">
        <Img fluid={fluid} />
      </div>
      <figcaption>
        <h4 className="mt-5 text-3xl text-blue-400 font-bold leading-none">
          {name}
        </h4>
        <div className="mt-2 text-xs uppercase text-gray-300">{title}</div>
        <p className="mt-4 text-sm  xl:text-base text-gray-400">{bio}</p>
      </figcaption>
    </figure>
  )
}
