import React from 'react'
import { Link } from '@elements'

export const CTA = () => {
  return (
    <div className="my-16 md:mt-24 text-center">
      <h2 className="text-4xl text-blue-400 font-bold leading-tight">
        Take a look at our&nbsp;work
      </h2>
      <Link to="/gallery/" className="btn  text-lg sm:text-xl mt-6">
        view the gallery
      </Link>
    </div>
  )
}
